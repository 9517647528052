//each component is its own js file

const NameTag = ({name}) => {
    return(
        <>
        <p>{name}</p>
        </>
    )
}

export default NameTag;